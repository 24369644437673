import {Element, getAlertIcon, getUrlParam, renderRevealPasswordFor} from "./login_lib";

$(function() {
	renderLogin();
});
function renderLogin() {
	var body=$('body');
	var hasLoggedOut=getUrlParam("logout", false);
	var autoLogout=getUrlParam("autoLogout", false);
	var isCredentialInvalid=getUrlParam("invalid", false);
	var isSSOInvalid=getUrlParam("invalidSSO", false);
	var isCredentialInvalidWithWarning=getUrlParam("invalidLockoutWarn", false);
	var isAccountLocked=getUrlParam("locked", false);
	var isAccountInactive=getUrlParam("inactive", false);
	var product="$$$PRODUCT$$$";
	if (product.toLowerCase()==='payer') product='iCES';
	else if (product.toLowerCase()==='provider') product='CM';
	body.append(
		renderHeader(),
		renderSignIn(),
		renderFooter()
	);
	function renderSignIn() {
		var signInElements, errorElement, errorMessage, usernameInput, passwordInput, signInButton, userIdContainer,
			passwordErrorContainer, userIdErrorElement, passwordErrorElement, successElement, successMessage, alertElement,
			alertMessage;
		signInElements=Element(null).append(
			errorElement=Element('div', {class:'messageBox alert hidden'}).append(
				Element('span', {class:"icon"}).append(
					Element('svg', {viewBox:"0 0 24 24"}).append(
						Element('path', {fill:"#FFF", d:"M12,24c-3.2,0-6.3-1.3-8.5-3.5c-4.6-4.6-4.6-12.3,0-17C5.7,1.3,8.8,0,12,0s6.3,1.3,8.5,3.5S24,8.8,24,12s-1.3,6.3-3.5,8.5C18.2,22.9,15.2,24,12,24z M12,2.1C9.3,2.1,6.9,3,4.9,5c-3.8,3.8-3.8,10.3,0,14.1C6.9,21,9.3,22,12,22s5.1-1,7.1-2.9s2.9-4.3,2.9-7.1S21,6.9,19,5S14.5,2.1,12,2.1z"}),
						Element('path', {fill:"#FFF", d:"M10.7,13.1l-0.3-5.3c0-1,0-1.6,0-2.1c0-0.6,0.2-1.1,0.5-1.4C11,4,11.5,3.8,12,3.8c0.6,0,1,0.2,1.3,0.6c0.2,0.5,0.3,1.1,0.3,1.9c0,0.5,0,1,0,1.4l-0.6,5.3c0,0.6-0.2,1.1-0.3,1.4c-0.2,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.6-0.2-0.8-0.5C10.9,14.3,10.9,13.8,10.7,13.1z M12,20.4c-0.5,0-0.8-0.2-1.1-0.5s-0.5-0.6-0.5-1.3c0-0.5,0.2-0.8,0.5-1.1S11.5,17,12,17s0.8,0.2,1.1,0.5s0.5,0.8,0.5,1.1c0,0.5-0.2,1-0.5,1.1C12.8,20.2,12.3,20.4,12,20.4z"})
					)
				),
				errorMessage=Element('div', {class:"message"})
			),
			successElement=Element('div', {class:'messageBox success hidden'}).append(
				Element('span', {class:"icon"}).append(
					Element('svg', {viewBox:"0 0 32 32"}).append(
						Element('path', {fill:"#FFF", d:"M15.9,0.6C7.4,0.6,0.4,7.5,0.4,16s6.9,15.5,15.5,15.5s15.5-7,15.5-15.5S24.5,0.5,15.9,0.6z M15.9,30.5C7.9,30.5,1.4,24,1.4,16S7.9,1.5,15.9,1.5S30.5,8,30.5,16S24,30.5,15.9,30.5z"}),
						Element('path', {fill:"#FFF", d:"M12.9,21.2l-5.7-5.6l-0.7,0.7l6.3,6.4l12.3-12.4l-0.7-0.7L12.9,21.2z"})
					)
				),
				successMessage=Element('div', {class:"message"})
			),
			alertElement=Element('div', {class:'messageBox warning hidden'}).append(
				Element('span', {class:"icon"}).append(
					Element('svg', {viewBox:"0 0 32 32"}).append(
						Element('path', {fill:"#fff", d:"M30.6 26.15l-12.8-22.5c-0.4-0.75-1.2-1.2-2.050-1.2s-1.6 0.45-2.050 1.2l-12.8 22.5c-0.4 0.75-0.4 1.6 0 2.35s1.2 1.2 2.050 1.2h25.65c0.85 0 1.6-0.45 2.050-1.2 0.4-0.75 0.4-1.6-0.050-2.35zM29.75 28c-0.25 0.45-0.7 0.7-1.15 0.7h-25.65c-0.5 0-0.9-0.25-1.15-0.7s-0.25-0.95 0-1.4l12.8-22.5c0.25-0.45 0.7-0.7 1.15-0.7 0.5 0 0.9 0.25 1.15 0.7l12.8 22.5c0.3 0.45 0.3 1 0.050 1.4z"}),
						Element('path', {fill:"#fff", d:"M15.75 22.4c-0.45 0-0.8 0.15-1.1 0.45s-0.45 0.7-0.45 1.1c0 0.5 0.15 0.9 0.45 1.15 0.3 0.3 0.7 0.4 1.1 0.4s0.8-0.15 1.1-0.4c0.3-0.3 0.45-0.65 0.45-1.15 0-0.45-0.15-0.8-0.45-1.1s-0.65-0.45-1.1-0.45z"}),
						Element('path', {fill:"#fff", d:"M15.85 9.6c-0.5 0-0.9 0.15-1.2 0.5s-0.45 0.8-0.45 1.35c0 0.45 0.050 1.15 0.1 2.1l0.35 5.050c0.050 0.65 0.15 1.15 0.3 1.45s0.4 0.5 0.8 0.5c0.35 0 0.65-0.15 0.8-0.5s0.25-0.8 0.3-1.4l0.45-5.2c0.050-0.5 0.050-0.95 0.050-1.4 0-0.8-0.1-1.4-0.3-1.8-0.2-0.45-0.6-0.65-1.2-0.65z"})
					)
				),
				alertMessage=Element('div', {class:"message"})
			),
			Element('div', {class:"signInContainer"}).append(
				Element('div', {class:"header", text:"Sign In"}),
				Element('div', {class:"label", text:"User Name"}),
				Element('div', {class:"data"}).append(
					usernameInput=Element('input', {type:"text"}).on("input", function() {
						hideErrorMessage();
						hideSuccessMessage();
						hideWarnigMessage();
					}).on('keydown', keyDown).focusAfter()
				),
				userIdContainer=Element('div', {class:'errorContainer'}).append(Element('div', {class:'alertContainer'}).append(getAlertIcon()), userIdErrorElement=Element('span', {class:'errorMsg'})),
				Element('div', {class:"label", text:"Password"}),
				Element('div', {class:"data"}).append(
					passwordInput=Element('input', {type:"password"}).on("input", function() {
						hideErrorMessage();
						hideSuccessMessage();
						hideWarnigMessage();
					}).on('keydown', keyDown)
					.on('keyup', keyUp),
					renderRevealPasswordFor(passwordInput)
				),
				passwordErrorContainer=Element('div', {class:'errorContainer'}).append(Element('div', {class:'alertContainer'}).append(getAlertIcon()), passwordErrorElement=Element('span', {class:'errorMsg'})),
				signInButton=Element('button').append(
					"Sign In"
				).prop('disabled', false).click(signIn)
			)
		);
		userIdContainer.addClass('hidden');
		passwordErrorContainer.addClass('hidden');
		if (hasLoggedOut) {
			setSuccesMessage("You have successfully signed out.");
			setTimeout(function() {hideSuccessMessage();}, 5000);
		}
		if (autoLogout) {
			setWarningMessage("You have been signed out due to inactivity.");
		}
		if (isCredentialInvalid) {
			setErrorMessage("The username or password you entered was incorrect.");
			setTimeout(function() {hideErrorMessage();}, 10000);
		}
		if (isSSOInvalid) {
			setErrorMessage("Login failed. Please contact your system administrator.");
			setTimeout(function() {hideErrorMessage();}, 10000);
		}
		if (isCredentialInvalidWithWarning) {
			setErrorMessage("The username or password you entered was incorrect. The account will be locked after next unsuccessful attempt.");
			setTimeout(function() {hideErrorMessage();}, 10000);
		}
		if (isAccountLocked) {
			setErrorMessage("This account has been locked due to several unsuccessful sign in attempts or password expiration. Please contact your system administrator.");
			setTimeout(function() {hideErrorMessage();}, 10000);
		}
		if (isAccountInactive) {
			setErrorMessage("This account has been inactive. Please contact your system administrator.");
			setTimeout(function() {hideErrorMessage();}, 10000);
		}
		return signInElements;
		function credentialsIsNotEmpty() {
			var isInvalid=false;
			if (!usernameInput.val().length) {
				userIdErrorElement.empty();
				if (userIdContainer.hasClass('hidden')) userIdContainer.removeClass('hidden');
				userIdErrorElement.append("User Name is required");
				isInvalid=true;
			}
			if (!passwordInput.val().length) {
				passwordErrorElement.empty();
				if (passwordErrorContainer.hasClass('hidden')) passwordErrorContainer.removeClass('hidden');
				passwordErrorElement.append("Password is required");
				isInvalid=true;
			}
			if (isInvalid) {
				setErrorMessage("The highlighted error must be corrected before you can continue.");
				return false;
			}
			else {
				hideErrorMessage();
				return true;
			}
		}
		function keyDown(event) {
			if (event.keyCode===13) signIn();
		}
		function keyUp(event) {
			let isCapsLockOn = event.originalEvent.getModifierState("CapsLock");
			if (isCapsLockOn){
				setWarningMessage("Caps Lock is on.");
			} else {
				hideWarnigMessage();
			}
			
		}
		function signIn() {
			if (credentialsIsNotEmpty()) {
				var submitForm=Element('form', {
					action:'localIDP/j_security_check',
					method:'POST'
				}).append(
					Element('input', {type:'text', name:'j_username', value:usernameInput.val()}),
					Element('input', {type:'password', name:'j_password', value:passwordInput.val()}),
					Element('input', {type:'text', name:'SAMLRequest', value:new URLSearchParams(location.search).get('SAMLRequest')})
				);
				submitForm.appendTo('body').submit();
			}
			else setTimeout(function() {hideErrorMessage()}, 5000);
		}
		function setErrorMessage(message) {
			hideWarnigMessage();
			hideSuccessMessage();
			errorMessage.text(message);
			if (errorElement.hasClass('hidden')) errorElement.removeClass('hidden');
		}
		function hideErrorMessage() {
			if (!errorElement.hasClass('hidden')) errorElement.addClass('hidden');
			if (!userIdContainer.hasClass('hidden')) userIdContainer.addClass('hidden');
			if (!passwordErrorContainer.hasClass('hidden')) passwordErrorContainer.addClass('hidden');
		}
		function setSuccesMessage(message) {
			successMessage.text(message);
			if (successElement.hasClass('hidden')) successElement.removeClass('hidden');
		}
		function hideSuccessMessage() {
			if (!successElement.hasClass('hidden')) successElement.addClass('hidden');
		}
		function setWarningMessage(message) {
			alertMessage.text(message);
			if (alertElement.hasClass('hidden')) alertElement.removeClass('hidden');
		}
		function hideWarnigMessage() {
			if (!alertElement.hasClass('hidden')) alertElement.addClass('hidden');
		}
	}
	function renderHeader() {
		return Element('header').append(
			Element('h1', {class:product.toLowerCase()}).append(Element('span', {class:'logo'}))
		);
	}
	function renderFooter() {
		var w=800, h=800, l=(screen.availWidth-w)/2, t=(screen.availHeight-h)/2;
		return Element('footer').append(
			Element('div').append(
				Element('span', {text:"© 2022 Optum, Inc. All rights reserved."}),
				Element('span', {class:"link", text:"Privacy Policy"}).click(function() {window.open(window.location.origin+'/localIDP/login/privacy_policy.html', "window","width= "+ w + ",height=" + h + ",left=" + l + ",top=" + t + ", scrollbars = yes, location = no, toolbar = no, menubar = no, status = no")}),
				Element('span', {class:"separator", text:"|"}),
				Element('span', {class:"link", text:"Terms of Use"}).click(function() {window.open(window.location.origin+'/localIDP/login/terms_of_use.html', "window","width= "+ w + ",height=" + h + ",left=" + l + ",top=" + t + ", scrollbars = yes, location = no, toolbar = no, menubar = no, status = no")})
			)
		)
	}
}
